<!-- eslint-disable  -->
<template>
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-row v-if="fileExists">
        <b-col cols="12" md="12" class="d-flex align-items-center justify-content-start mb-1 mb-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="md"
            class="mr-50"
            @click="saveData(true)"
          >
            Importar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            size="md"
            style="margin-right: 2px"
            @click="saveData(false)"
          >
            Prueba
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            size="md"
            style="margin-right: 2px"
            @click="cambioTipoSchema"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card class="flex" id="app">
            <b-card-text
              v-if="!fileExists"
              class="flex w-full h-screen items-center justify-center"
            >
              <b-row class="mb-1">
                <b-col md="10" sm="12" class="mt-1 mt-md-0">
                  <div
                    class="d-flex h-100 align-items-center justify-content-center justify-content-md-start"
                  >
                    <!-- <h5 class="mr-2 mb-0">Ayuda</h5> -->
                    <!-- <b-link
                      class="mb-1"
                      download="productos.xlsx"
                      href="/assets/files/productos.xlsx"
                    >
                      <div class="d-flex mb-25">
                        <feather-icon icon="DownloadCloudIcon" size="17" />
                        <span class="ml-25">Descargar formato</span>
                      </div>
                    </b-link> -->
                    <b-link :download="nameUrl" :href="urlHelp">
                      <b-button class="mb-0" variant="success">
                        <div class="d-flex">
                          <feather-icon icon="DownloadCloudIcon" size="17" />
                          <span class="ml-25">Descargar formato</span>
                        </div>
                      </b-button>
                    </b-link>
                  </div>
                </b-col>
                <b-col md="2" sm="12">
                  <div class="d-flex align-items-center w-100">
                    <b-button variant="danger" class="w-100" @click="back"> Volver </b-button>
                  </div>
                  <!-- <span>Tipo Importación:</span>
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="optionsSelected"
                  label="text"
                  size="md"
                  input-id="area"
                  placeholder="Importación"
                  :reduce="(optionsSelected) => optionsSelected.value"
                  v-model="selected"
                  @input="cambioTipoSchema"
                >
                </v-select> -->
                </b-col>
              </b-row>
              <div
                @click="$refs.file.$el.click()"
                style="height: 400px !important"
                class="p-12 bg-gray-100 border border-gray-300 text-center d-flex justify-content-center align-items-center"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
              >
                <b-form-file
                  :hidden="true"
                  plain
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChangeFile"
                  ref="file"
                  accept=".xls,.xlsx,.csv"
                />
                <label for="assetsFieldHandle" class="block cursor-pointer">
                  <div class="text-center mt-2">
                    <b-img
                      ref="previewEl"
                      rounded
                      :src="require('@/assets/images/icons/file.jpg')"
                      height="80"
                    />
                    <h4>Upload an Excel file to import</h4>
                    <span class="mb-2">Click o arrastre el archivo</span><br />
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="md"
                      class="mb-75 mr-75 mt-2"
                    >
                      Subir Archivo
                    </b-button>
                  </div>
                </label>
              </div>
            </b-card-text>
            <b-card-text v-else>
              <b-row>
                <b-col md="2">
                  <div class="sidebar-left">
                    <div class="sidebar mr-25">
                      <div class="sidebar-content">
                        <h5 class="filter-title mt-0 mb-2">Información del archivo</h5>
                        <div>
                          <b-link class="mb-1">
                            <div class="d-flex mb-25">
                              <feather-icon icon="FileTextIcon" size="17" />
                              <span class="ml-25">{{ configurationFile.name }}</span>
                            </div>
                          </b-link>
                          <b-form-checkbox
                            v-model="configurationFile.firstRowHeader"
                            @change="cambioLogicHeader"
                          >
                            Primera fila es cabecera?
                          </b-form-checkbox>
                        </div>
                        <!-- <h5 class="mt-2 mb-2">Ayuda</h5>
                      <div>
                        <b-link
                          class="mb-1"
                          download="productos.xlsx"
                          href="/assets/files/productos.xlsx"
                        >
                          <div class="d-flex mb-25">
                            <feather-icon icon="DownloadCloudIcon" size="17" />
                            <span class="ml-25">Descargar formato</span>
                          </div>
                        </b-link>
                      </div> -->
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col md="10">
                  <b-list-group class="ml-3">
                    <b-list-group-item
                      v-for="(item, index) of schema"
                      :key="item.prop"
                      class="flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          {{ item.title }}
                        </h5>
                        <small class="text-secondary">ej: {{ item.example }}</small>
                      </div>
                      <b-card-text class="mb-1">
                        <b-row>
                          <b-col md="6">
                            <b-form-select
                              v-model="schema[index].id"
                              text-field="text"
                              value-field="id"
                              :options="headers"
                            />
                          </b-col>
                          <b-col md="6">
                            <b-alert class="p-1" variant="danger" :show="item.errors.length > 0">
                              <b-card-text class="ml-1 mb-1 mr-1">
                                <span v-if="item.validateCall"
                                  >No se encontraron registros con los datos ingresados como
                                  {{ item.title }}.</span
                                >
                                <span v-else>Se encontraron los siguientes errores: </span>
                              </b-card-text>
                              <ul>
                                <li
                                  v-for="(error, i) of item.seeAllErrors
                                    ? item.errors
                                    : item.errors.slice(0, lengthMax)"
                                  :key="i"
                                >
                                  {{ error }}
                                </li>
                              </ul>
                              <div class="d-flex justify-content-center">
                                <b-button
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="primary"
                                  v-if="item.errors.length > 3"
                                  @click="seeAllErrors(index)"
                                   class="mr-1"
                                >
                                  Ver todos
                                </b-button>
                                <b-button
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="secondary"
                                  v-if="item.errors.length > 3"
                                  @click="seeLessErrors(index)"
                                >
                                  Ver Menos
                                </b-button>
                              </div>
                            </b-alert>
                            <b-alert
                              variant="danger"
                              :show="item.errors.length > 0 && item.validateCall"
                            >
                              <b-card-text class="ml-1 mb-1 mr-1">
                                <span>Cuando no se encuentran valores enlazados: </span>
                                <!-- <b-form-select
                                v-model="item.actionMatch"
                                :options="actionsMatch"
                                size="md"
                              /> -->
                              </b-card-text>
                            </b-alert>
                          </b-col>
                        </b-row>
                      </b-card-text>
                      <small class="text-secondary"></small>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import readXlsxFile from 'read-excel-file'
// import jsonData from "@/generalData.json";
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import RfiService from '@/services/RfiService'
const API_URL = process.env.APIURL
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  components: {
    vSelect
  },
  data() {
    return {
      showLoading: false,
      optionsCuenta: [],
      formatos: [
        {
          name: 'venta',
          url: '/assets/files/productos.xlsx'
        },
        {
          name: 'compra',
          url: '/assets/files/rep_ventas_aprobadas.xlsx'
        }
      ],
      nameUrl: 'EjemploRFI.xlsx',
      urlHelp: '/files/EjemploRFI.xlsx',
      selected: 'Venta',
      optionsSelected: [
        { value: 'venta', text: 'Venta' },
        { value: 'compra', text: 'Compra' },
        { value: 'clientes/proveedores', text: 'Cliente y Proveedores' },
        { value: 'productos', text: 'Productos' }
      ],
      contentFile: null,
      headers: [],
      schema: [],
      fileExists: false,
      configurationFile: {
        name: '',
        extension: '',
        size: 0,
        firstRowHeader: true
      },
      // actionsMatch: jsonData.import.actionsMatch,
      lengthMax: 3,
      file: null,
      firstRow: null
    }
  },
  async mounted() {
    const resp = await this.$store.dispatch('back/GET_GENERAL')
    console.log('resp', resp)
    this.schema = resp.rfiSchema
    // await this.getDataCuenta();
    // this.selected = this.$route.params.type;
  },
  directives: {
    Ripple
  },
  methods: {
    back() {
      this.$router.push('/rfi')
    },
    async cambioTipoSchema() {
      this.cancelarImportacion()
      const resp = await this.$store.dispatch('back/GET_GENERAL')
      this.schema = resp.rfiSchema
    },
    async saveData(save_data) {
      this.showLoading = true
      let formData = new FormData()
      formData.append('file', this.file)
      var schemaSend = {
        save: save_data,
        schema: this.schema
      }
      console.log('schemaSend',schemaSend)
      formData.append('schema', JSON.stringify(schemaSend))
      const respImport = await RfiService.saveImport(formData, this.$store)
      console.log('respImport',respImport)
      if (respImport.status) {
        console.log('respImport', respImport)
        var findErr = false
        for (let i = 0; i < respImport.data.schema.length; i++) {
          this.schema[i].errors = respImport.data.schema[i].errors
          if (respImport.data.schema[i].errors.length > 0) {
            findErr = true
          }
        }
        console.log('findErr',findErr)
        if (save_data && !findErr) {
          this.cancelarImportacion()
          this.$swal({
            icon: 'success',
            title: 'Importado!',
            text: 'Las rfi ha sido importadas con exito.',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        } else if (!save_data && findErr) {
          console.log('respImport', respImport)
          this.$swal({
            icon: 'warning',
            title: 'Errores',
            text: 'Se presentaron los siguientes errores.',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        } else {
          this.$swal({
            icon: 'success',
            title: 'Éxito',
            text: 'La prueba se realizó con éxito.',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al importar la rfi.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
      this.showLoading = false
    },
    showMessage(mensaje, icon, title) {
      this.$swal({
        icon: icon,
        title: title,
        html: mensaje,
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    seeAllErrors(index) {
      this.schema[index].seeAllErrors = true
    },
    seeLessErrors(index) {
      this.schema[index].seeAllErrors = false
    },
    cambioLogicHeader() {
      this.headers = []
      if (this.configurationFile.firstRowHeader) {
        for (let i = 0; i < this.firstRow.length; i++) {
          this.headers.push({ id: i, text: this.firstRow[i] })
        }
      } else {
        for (let i = 0; i < this.firstRow.length; i++) {
          this.headers.push({ id: i, text: 'Columna ' + (i + 1) })
        }
      }
    },
    async cancelarImportacion() {
      this.fileExists = false
      this.headers = []
      this.file = null
      this.firstRow = null
      this.configurationFile = {
        name: '',
        extension: '',
        size: 0,
        firstRowHeader: true
      }
    },
    processFile(xlsxfile) {
      this.configurationFile.name = xlsxfile.name
      this.configurationFile.size = xlsxfile.size

      readXlsxFile(xlsxfile, { sheet: 1 }).then((rows) => {
        if (rows.length > 0) {
          this.fileExists = true
          this.headers = []
          this.firstRow = rows[0]
          if (this.configurationFile.firstRowHeader) {
            for (let i = 0; i < rows[0].length; i++) {
              this.headers.push({ id: i, text: rows[0][i] })
            }
          }
        }
      })
    },
    onChangeFile(event) {
      this.file = event.target.files ? event.target.files[0] : null
      if (this.file) {
        this.processFile(this.file)
      }
    },
    dragover(event) {
      event.preventDefault()
    },
    dragleave(event) {},
    drop(event) {
      event.preventDefault()
      this.file = event.dataTransfer.files ? event.dataTransfer.files[0] : null
      if (this.file) {
        this.processFile(this.file)
      }
    },
    async getDataCuenta() {
      // var request = {
      //   url: API_URL + "admin/general/user_roles",
      //   method: "GET",
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      //   },
      // };
      // const resp = await this.$store.dispatch("back/EXECUTE", request);
      // var arrayTemp = [];
      // if (resp.status) {
      //   for (let index = 0; index < resp.data.length; index++) {
      //     const element = resp.data[index];
      //     arrayTemp.push({ text: element.account, value: element.account_id });
      //   }
      // }
      // this.optionsCuenta = arrayTemp;
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.mt-05 {
  margin-top: 0.5rem;
}
</style>
