/* eslint-disable*/
import Vue from 'vue';
import VueStoreService from './VueStoreService';
const RfiService = {
    async getRfiReport(url,store){
        var resp = await VueStoreService.get("rfi/all"+url, store)
        return resp;
    },
    async getRfi(url,store){
        var resp = await VueStoreService.get("rfi"+url, store)
        return resp;
    },
    async getRfiById(id,store){
        var resp = await VueStoreService.get("rfi/"+id, store)
        return resp;
    },
    async saveRfi(data,store){
        var resp = await VueStoreService.post("rfi",data,store)
        return resp;
    },
    async updateRfi(rest_id,data,store){
        var resp = await VueStoreService.put("rfi/"+rest_id,data,store)
        return resp;
    },

    async deleteRfi(rest_id,store){
        var resp = await VueStoreService.delete("rfi/"+rest_id,store)
        return resp;
    },

    //IMPORTAR
    async saveImport(data,store){
        var resp = await VueStoreService.post("rfi/upload",data,store)
        return resp;
    },
    //IMPORTAR
    async exportExcel(id,store){
        var resp = await VueStoreService.getFile("rfi/download/"+1,store)
        return resp;
    },

    //CAMBIO DE ESTADO
    async saveChangeStatus(data,store){
        var resp = await VueStoreService.post("rfi/status_masive",data,store)
        return resp;
    },

    //COMENTARIOS
    async getComments(rest_id,store){
        var resp = await VueStoreService.get("history-comment-rfi/"+rest_id, store)
        return resp;
    },
    async saveComent(data,store){
        var resp = await VueStoreService.post("history-comment-rfi",data,store)
        return resp;
    },

    //DATES
    async getDates(rest_id,store){
        var resp = await VueStoreService.get("history-date-rfi/"+rest_id, store)
        return resp;
    },
}

export default RfiService;